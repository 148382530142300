import React, { FC, useCallback, useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment";
import { capitalize } from "lodash";
import PlayIcon from "../audioPlayer/icon_play.svg";
import PauseIcon from "../audioPlayer/icon_pause.svg";
import ReactSlider from "react-slider";
import ReactAudioPlayer from "react-audio-player";

const GREG_THINKS_BASE_URL =
  "https://eiw077wanh.execute-api.us-east-1.amazonaws.com/qa";

interface Props {}

//Open weather API KEY. Please move this to env
const OPEN_WEATHER_API_KEY = "4f32c214736d8af063d22e13284e5b9b";

//Helper method to generate greetings based on time of the day
const generateGreetings = () => {
  const currentHour = parseInt(moment().format("HH"));

  if (currentHour >= 3 && currentHour < 12) {
    return "Good Morning";
  } else if (currentHour >= 12 && currentHour < 15) {
    return "Good Afternoon";
  } else if (currentHour >= 15 && currentHour < 20) {
    return "Good Evening";
  } else if (currentHour >= 20 && currentHour < 3) {
    return "Good Night";
  } else {
    return "Hey";
  }
};

const isDay = () => {
  const currentHour = parseInt(moment().format("HH"));
  return currentHour <= 19;
};

const DEFAULT_BACKGROUND_IMAGE =
  "https://greg-stagingsoat2-public.s3-ap-southeast-2.amazonaws.com/protected/greg-think/backgrounds/Mint-Surfing-Background-Big.png";

const GregThinks: FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<any>(null);
  const [title, setTitle] = useState<any>(null);
  const [gregThinksData, setGregThinksData] = useState<any>(null);
  const [shouldHide, setShouldHide] = useState<boolean>(false);
  const [cardImageURL, setCardImageURL] = useState<any>(null);
  const [location, setLocation] = useState<any>(null);
  const [backgroundImageURL, setBackgroundImageURL] = useState<any>(null);
  const [temp, setTemp] = useState<any>(null);
  const [track, setTrack] = useState<any>(null);
  const [playing, setPlaying] = useState<boolean>(false);
  const [currentProgress, setCurrentProgress] = useState<string>("0.00");
  const [currentProgressInSeconds, setCurrentProgressInSeconds] =
    useState<number>(0);
  const [totalDuration, setTotalDuration] = useState<string>("0.00");
  const [totalDurationInSeconds, setTotalDurationInSeconds] =
    useState<number>(0);
  const [weather, setWeather] = useState<any>(null);
  const [progressInterval, setProgressInterval] = useState<any>(null);
  const [progressBarVal, setProgressBardVal] = useState<any>(0);
  const [audioPlayerRef, setAudioPlayerRef] = useState<any>(null);
  const [audioReady, setAudioReady] = useState<boolean>(false);

  const loadData = useCallback(async () => {
    const requestURL =
      GREG_THINKS_BASE_URL +
      `/api/v1/gregthink/${moment().format("YYYY-MM-DD")}`;
    console.log(requestURL);
    axios
      .get(requestURL, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((resp) => {
        setShouldHide(false);
        setGregThinksData(resp.data);
        setTitle(resp.data.title);
        setCardImageURL(resp.data.imageUrl.card);
        setBackgroundImageURL(
          Math.max(window.screen.width, window.innerWidth) <= 600
            ? resp.data.imageUrl?.background
            : resp.data.imageUrl.webBackground
        );
      })
      .catch((error) => {
        console.log("Error : " + error.message);
        setBackgroundImageURL(DEFAULT_BACKGROUND_IMAGE);
        setShouldHide(true);
      });
  }, [token]);
  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <div
        className="root-container"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.52) ,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.52)), url(${backgroundImageURL})`,
        }}
      >
        {/* <img style={{width:'100vw',height:'100vh'}} src={backgroundImageURL}/> */}
        <div id="inner-container">
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <a href="https://getgreg.app">
              <img
                src="https://mk0gregwebsiteoyvsxx.kinstacdn.com/wp-content/uploads/2020/06/Greg_White.svg"
                className="logo"
              />
            </a>
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-end",
                width: "200px",
              }}
            >
              <a href="#" className="primary-btn">
                Sign Up
              </a>
              <a href="#" className="secondary-btn mt-16p">
                Log in
              </a>
            </div>
          </div>
          <div className="content-block">
            <div id="greg-thinks-label">
              <p
                style={{
                  fontFamily: "Platform,sans-serif",
                  fontWeight: 700,
                  color: "#FFF",
                  lineHeight: "34px",
                  fontSize: "29px",
                }}
              >
                Greg thinks...
              </p>
            </div>

            <div id="content-container">
              <p
                style={{
                  fontFamily: "Platform,sans-serif",
                  fontWeight: 700,
                  color: "#FFF",
                  textShadow: "1px 2px 8px rgba(9, 4, 70, 0.2)",
                  lineHeight: "34px",
                  fontSize: "29px",
                  maxWidth: "400px",
                }}
              >
                {gregThinksData?.content
                  ? gregThinksData.content
                  : "Today is gonna be the day. That they're gonna throw it back to you. By now you should've somehow Realized what you gotta do…"}
              </p>
              {gregThinksData?.type == "TEXT" && (
                <p className="description-content">
                  {gregThinksData?.description}
                </p>
              )}
              {gregThinksData?.type == "AUDIO" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    maxWidth: "400px",
                  }}
                >
                  <img
                    src={playing ? PauseIcon : PlayIcon}
                    onClick={() => {
                      if (audioPlayerRef) {
                        playing
                          ? audioPlayerRef?.audioEl?.current.pause()
                          : audioPlayerRef?.audioEl?.current.play();
                      }
                      setPlaying(!playing);
                    }}
                    className="playIcon"
                  ></img>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                    }}
                  >
                    {/* <div style={{width:'100%',height:'4px',borderRadius:'8px',backgroundColor:'rgba(108, 117, 125, 0.6)'}}>
                    <div style={{width:'50%',height:'4px',position:'absolute',top:0,right:0,bottom:0,left:0,backgroundColor:'#fff',borderRadius:'8px'}}></div>
                    <div style={{width:'16px',height:'16px',position:'absolute',zIndex:2,backgroundColor:'#FFF',borderRadius:'50%',top:'-px'}}></div>                  
                  </div> */}
                    <ReactSlider
                      value={Math.round(
                        100 -
                          ((totalDurationInSeconds - currentProgressInSeconds) /
                            totalDurationInSeconds) *
                            100
                      )}
                      min={0}
                      max={100}
                      disabled={!audioReady}
                      onChange={(time: any) => {
                        if (audioPlayerRef?.audioEl?.current) {
                          setCurrentProgressInSeconds(
                            Math.round((time / 100) * totalDurationInSeconds)
                          );
                          audioPlayerRef.audioEl.current.currentTime =
                            Math.round((time / 100) * totalDurationInSeconds);
                        }
                      }}
                      className="horizontal-slider"
                      thumbClassName="audio-track-thumb"
                      trackClassName="audio-track"
                    />
                    <ReactAudioPlayer
                      src={gregThinksData?.audioUrl}
                      onEnded={() => {
                        setCurrentProgressInSeconds(0);
                        setPlaying(false);
                        setCurrentProgress("0.00");
                        audioPlayerRef.audioEl.current.currentTime = 0;
                      }}
                      ref={(element: any) => setAudioPlayerRef(element)}
                      onCanPlay={() => setAudioReady(true)}
                      listenInterval={1000}
                      onLoadedMetadata={(meta: any) => {
                        const { duration } = meta.target;
                        let time = Math.ceil(duration);
                        setTotalDurationInSeconds(time);
                        setTotalDuration(
                          (time - (time %= 60)) / 60 +
                            (9 < time ? ":" : ":0") +
                            time
                        );
                      }}
                      onListen={(timeSec: number) => {
                        let time = Math.ceil(timeSec);
                        console.log(time);
                        setCurrentProgressInSeconds(time);
                        setCurrentProgress(
                          (time - (time %= 60)) / 60 +
                            (9 < time ? ":" : ":0") +
                            time
                        );
                      }}
                    />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "8px",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Inter",
                          fontSize: 13,
                          fontWeight: 400,
                          color: "rgb(233, 236, 239)",
                          lineHeight: "20px",
                        }}
                      >
                        {currentProgress}
                      </span>
                      <span
                        style={{
                          fontFamily: "Inter",
                          fontSize: 13,
                          fontWeight: 400,
                          color: "rgb(233, 236, 239)",
                          lineHeight: "20px",
                        }}
                      >
                        {totalDuration}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div></div>
            </div>
            <div id="content-spacer"></div>
          </div>
          <div className="footer">
            <div id="module-app-store">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <p
                  style={{
                    fontFamily: "Inter",
                    fontSize: 13,
                    color: "#FFF",
                    fontWeight: 400,
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Download our mobile app
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <a
                    className="app-store-cta"
                    target="_blank"
                    href="https://apps.apple.com/us/app/greg-mens-wellness-health/id1513306357"
                  >
                    <img src="https://greg-email.s3-ap-southeast-2.amazonaws.com/buttons/AppStore%402x.png" />
                  </a>
                  <a
                    className="app-store-cta"
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=app.getgreg.mobile"
                  >
                    <img src="https://greg-email.s3-ap-southeast-2.amazonaws.com/buttons/GooglePlay%402x.png" />
                  </a>
                </div>
              </div>
            </div>

            <div id="footer-spacer"></div>
            <div className="footer-emblem">
              <a href="https://getgreg.app">
                <p
                  style={{
                    fontFamily: "Platform,sans-serif",
                    fontWeight: 700,
                    margin: 0,
                    color: "#FFF",
                    width: "170px",
                    lineHeight: "30px",
                    fontSize: "30px",
                  }}
                >
                  Great mates. Better lives.
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GregThinks;
