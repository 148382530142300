import React from 'react';
import logo from './logo.svg';
import GregThinks from "./components/gregThinks";
import './App.css';

function App() {
  return (
    <GregThinks/>
  );
}

export default App;
